const renderurl = process.env.RENDER_URL;
const netlifyurl = process.env.FRONT_URL;

window.ondblclick=Background_change_color_dbl;
var bg_switch = true;

function Background_change_color_dbl ()
{
  if (bg_switch){
    document.body.style.backgroundColor='#008083';
  }
  else{
    document.body.style.backgroundColor='#ffffff';
  }
  bg_switch = !bg_switch;
  
}


var url = document.location.href;
const user = url.substring(url.lastIndexOf("/") + 1, url.length);
console.log(user);
if (
  user &&
  user != " " &&
  user != "index.html" &&
  user != "findProfile.html" &&
  user != "editProfile.html" &&
  user != "login.html"
) {
  fetch(renderurl + "api/users/" + user)
    .then((response) => response.json())
    .then(function (data) {
      if (!data.username) {
        document.getElementById("api-test").innerHTML =
          "That screename does not exist, try another one!";
      } else {
        document.getElementById("api-test").innerHTML = data.username;
        document.getElementById("profile-title").innerHTML =
          "SuperProfile - " + data.username;
      }
    });
}


document.getElementById("login-btn").addEventListener("click", function () {
  const email = document.getElementById("email-input").value;
  const password = document.getElementById("pass-input").value;
  fetch(renderurl+"api/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((response) => response.json())
    .then(function (data) {
      if (!data.token) {
        document.getElementById("profile-title").innerHTML = "&#10060; Your Email or Password is incorrect";
        //setTimeout 1 second
        setTimeout(function () {
          document.getElementById("profile-title").innerHTML = "&#128075; Login";
        }, 1100);
      } else {
        localStorage.setItem("token", data.token);
        //go to editProfile.html
        window.location.href = netlifyurl + "editProfile.html";
      }
    });
});

//when authenticated, a jwt token is stored in local storage
const token = localStorage.getItem("token");

//this function parses the token and returns the user id
function parseJWT(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const decoded = JSON.parse(jsonPayload);
  return decoded.user.id;
}

if (token) {
  console.log(parseJWT(token));
}
